<template>
  <div
    class="offcanvas offcanvas-start"
    tabindex="-1"
    id="researchListFilters"
    aria-labelledby="offcanvasRightLabel"
  >
    <div class="offcanvas-header">
      <h5 id="offcanvasRightLabel">Filter the results</h5>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <!-- Select a faculty -->
      <div>
        <a
          class="btn w-100"
          data-bs-toggle="collapse"
          href="#facultyFilter"
          role="button"
          aria-expanded="true"
          aria-controls="facultyFilter"
        >
          <i class="float-start fs-6">Faculties</i>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-caret-down-fill float-end"
            viewBox="0 0 16 16"
          >
            <path
              d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
            />
          </svg>
        </a>
        <div class="collapse w-100 show" id="facultyFilter">
          <div
            class="card card-body my-2 border border-1 shadow bg-light w-100"
          >
            <template
              v-for="(faculty, index) in faculties"
              :key="faculty.id"
              :value="faculty.id"
            >
              <!-- all faculties -->
              <div v-if="index == 0" class="form-check form-check-custom mb-3">
                <label class="form-check-label" for="all">
                  <input
                    checked
                    type="radio"
                    id="%"
                    value="%"
                    class="form-check-input border-3"
                    name="faculty"
                    v-model="selectedFaculty"
                  />All</label
                >
              </div>
              <div class="form-check form-check-custom mb-3">
                <label class="form-check-label" :for="faculty.name">
                  <input
                    type="radio"
                    :id="faculty.id"
                    :value="faculty.id"
                    class="form-check-input border-3"
                    name="faculty"
                    v-model="selectedFaculty"
                  />{{ faculty.name }}</label
                >
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- select a category -->
      <div>
        <a
          class="btn w-100"
          data-bs-toggle="collapse"
          href="#categoryFilter"
          role="button"
          aria-expanded="true"
          aria-controls="categoryFilter"
        >
          <i class="float-start fs-6">Categories</i>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-caret-down-fill float-end"
            viewBox="0 0 16 16"
          >
            <path
              d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
            />
          </svg>
        </a>
        <div class="collapse w-100 show" id="categoryFilter">
          <div
            class="card card-body my-2 border border-1 shadow bg-light w-100"
          >
            <template
              v-for="(category, index) in researchCategories"
              :key="category.id"
              :value="category.id"
            >
              <!-- all categories -->
              <div v-if="index == 0" class="form-check form-check-custom mb-3">
                <label class="form-check-label" for="all">
                  <input
                    checked
                    type="radio"
                    id="%"
                    value="%"
                    class="form-check-input border-3"
                    name="category"
                    v-model="selectedCategory"
                  />All</label
                >
              </div>
              <div class="form-check form-check-custom mb-3">
                <label class="form-check-label" :for="category.name">
                  <input
                    type="radio"
                    :id="category.id"
                    :value="category.id"
                    class="form-check-input border-3"
                    name="category"
                    v-model="selectedCategory"
                  />{{ category.name }}</label
                >
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- select a type of study -->
      <div>
        <a
          class="btn w-100"
          data-bs-toggle="collapse"
          href="#typeOfStudyFilter"
          role="button"
          aria-expanded="true"
          aria-controls="typeOfStudyFilter"
        >
          <i class="float-start fs-6">Type of Study</i>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-caret-down-fill float-end"
            viewBox="0 0 16 16"
          >
            <path
              d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
            />
          </svg>
        </a>
        <div class="collapse w-100 show" id="typeOfStudyFilter">
          <div
            class="card card-body my-2 border border-1 shadow bg-light w-100"
          >
            <template
              v-for="(study, index) in studiesTypes"
              :key="study.id"
              :value="study.id"
            >
              <!-- all TypeOfStudy -->
              <div v-if="index == 0" class="form-check form-check-custom mb-3">
                <label class="form-check-label" for="all">
                  <input
                    checked
                    type="radio"
                    id="%"
                    value="%"
                    class="form-check-input border-3"
                    name="study"
                    v-model="selectedTypeOfStudy"
                  />All</label
                >
              </div>
              <div class="form-check form-check-custom mb-3">
                <label class="form-check-label" :for="study.name">
                  <input
                    type="radio"
                    :id="study.id"
                    :value="study.id"
                    class="form-check-input border-3"
                    name="study"
                    v-model="selectedTypeOfStudy"
                  />{{ study.name }}</label
                >
              </div>
            </template>
          </div>
        </div>
      </div>
      <template v-if="hasRole(['admin'])">
        <!-- select a status -->
        <a
          class="btn w-100"
          data-bs-toggle="collapse"
          href="#statusFilter"
          role="button"
          aria-expanded="true"
          aria-controls="statusFilter"
        >
          <i class="float-start fs-6">Status</i>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-caret-down-fill float-end"
            viewBox="0 0 16 16"
          >
            <path
              d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
            />
          </svg>
        </a>
        <div class="collapse w-100 show" id="statusFilter">
          <div
            class="card card-body my-2 border border-1 shadow bg-light w-100"
          >
            <template
              v-for="(status, index) in researchStatuses"
              :key="status.id"
              :value="status.id"
            >
              <!-- all research statuses -->
              <div v-if="index == 0" class="form-check form-check-custom mb-3">
                <label class="form-check-label" for="all">
                  <input
                    checked
                    type="radio"
                    id="%"
                    value="%"
                    class="form-check-input border-3"
                    name="status"
                    v-model="selectedStatus"
                  />All</label
                >
              </div>
              <div class="form-check form-check-custom mb-3">
                <label class="form-check-label" :for="status.name">
                  <input
                    type="radio"
                    :id="status.id"
                    :value="status.id"
                    class="form-check-input border-3"
                    name="study"
                    v-model="selectedStatus"
                  />{{ status.name }}</label
                >
              </div>
            </template>
          </div>
        </div>
      </template>
      <!-- apply filters button -->
      <div class="w-100 text-center my-3">
        <button
          class="btn btn-outline-primary"
          @click="filterResults"
          :disabled="in_submission"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15 19.5229C15 20.265 15.9624 20.5564 16.374 19.9389L22.2227 11.166C22.5549 10.6676 22.1976 10 21.5986 10H17V4.47708C17 3.73503 16.0376 3.44363 15.626 4.06106L9.77735 12.834C9.44507 13.3324 9.80237 14 10.4014 14H15V19.5229Z"
              fill="#3182ce"
            ></path>
            <path
              opacity="0.3"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 6.5C3 5.67157 3.67157 5 4.5 5H9.5C10.3284 5 11 5.67157 11 6.5C11 7.32843 10.3284 8 9.5 8H4.5C3.67157 8 3 7.32843 3 6.5ZM3 18.5C3 17.6716 3.67157 17 4.5 17H9.5C10.3284 17 11 17.6716 11 18.5C11 19.3284 10.3284 20 9.5 20H4.5C3.67157 20 3 19.3284 3 18.5ZM2.5 11C1.67157 11 1 11.6716 1 12.5C1 13.3284 1.67157 14 2.5 14H6.5C7.32843 14 8 13.3284 8 12.5C8 11.6716 7.32843 11 6.5 11H2.5Z"
              fill="#2670b6"
            ></path>
          </svg>
          Apply Filters
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { hasRole } from "@/includes/helpers.js";

export default {
  name: "researchListFilters",
  computed: {
    ...mapGetters({
      user: "user",
      faculties: "faculties",
      researchCategories: "categories",
      studiesTypes: "studyTypes",
      researchStatuses: "researchStatuses",
    }),
  },
  props: {
    in_submission: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["filterResults"],
  data() {
    return {
      selectedFaculty: null,
      selectedCategory: null,
      selectedTypeOfStudy: null,
      selectedStatus: null,
    };
  },
  methods: {
    async filterResults() {
      !this.in_submission
        ? this.$emit("filterResults", {
            selectedFaculty: this.selectedFaculty,
            selectedCategory: this.selectedCategory,
            selectedTypeOfStudy: this.selectedTypeOfStudy,
            selectedStatus: this.selectedStatus,
          })
        : "";
    },
    hasRole,
  },
};
</script>

<style></style>
